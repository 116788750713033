.content-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6.25rem 0 0 0;
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.content-block.left {
  flex-direction: row;
}

.content-block.right {
  flex-direction: row-reverse;
}

.content-block__image {
  flex: 1;
}

.content-block__image img {
  width: 100%;
  height: auto;
  display: block;
}

.content-block__text {
  flex: 1;
}

.content-block__text.left {
  padding-left: 10%;
}

.content-block__text.right {
  padding-right: 10%;
}

.content-block__text h3 {
  margin-top: 0;
  font-size: 28px;
}

.content-block__text p {
  line-height: 1.5;
}

.content-block__text button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.content-block__text button:hover {
  background-color: #555;
}

@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

.animate-slide-in-left {
  opacity: 1;
  animation: slideInFromLeft 1s ease-out forwards;
}

.animate-slide-in-right {
  opacity: 1;
  animation: slideInFromRight 1s ease-out forwards;
}

@media (max-width: 768px) {
  .content-block {
    flex-direction: column;
    margin: 60px 0;
    transform: none; 
  }

  .content-block.left, .content-block.right {
    flex-direction: column;
  }

  .content-block__text {
    order: 1;
    text-align: left; 
    padding-left: 0;
    padding-right: 0;
  }

  .content-block__image {
    order: 2;
    padding-left: 0;
    text-align: left; 
    margin: 20px 0;
  }

  .content-block__image img {
    width: 100%;
    height: auto;
  }

  .content-block__text:last-child {
    order: 3;
    text-align: left; 
    padding-left: 0;
    padding-right: 0;
  }
}
