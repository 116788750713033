
  .custom-dropdown {
    position: absolute;
    top: 65%;
    left: 0;
    width: 100vw;
    background-color: #ffffff00;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
  
  .dropdown-content {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    padding: 2rem 0 1rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background: linear-gradient(to bottom, 
      transparent 0%,   
      rgba(255, 255, 255, 0.096) 50%,  
      rgba(255, 255, 255, 0.24) 100%  
    );
  }
  
  .dropdown-col {
    flex: 1;
    margin: 0.5rem;
    padding: 10px;
    max-width: 12.5rem;
    text-align: center;
  }
  
  .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    border-radius: 6px;
    text-decoration: none;
    color: white;
    height: 10rem;
    transition: transform 0.2s;
  }
  
  .dropdown-item:hover {
    transform: scale(1.05);
  }
  
  .dropdown-image {
    width: 10rem;
    height: auto;
    border-radius: 6px;
    margin-bottom: 0.5rem;
    box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.5);

  }
  
  .dropdown-item:hover {
    background-color: #b3b3b33d;
  }
  
  .custom-dropdown.scrolled .dropdown-content.scrolled {
    background-color: white;
  }
  
  .custom-dropdown.scrolled .dropdown-item:hover {
    background-color: #b3b3b346;
  }

  .custom-dropdown.scrolled .dropdown-item{
    color: black;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1919px) {
    .dropdown-image {
      width: 8rem;
    }
    .dropdown-col{
      max-width: 11rem;
      margin: 0rem;
    }
    .dropdown-content{
      padding: 1rem 0 1rem 0;
    }
  }