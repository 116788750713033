@import "./components/CSS/ContentBlock.css";
@import "./components/CSS/HeroSection.css";

* {
  margin: 0;
  padding: 0;
}

html,
body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}


.App {
  text-align: center;
  overflow: hidden;
  
}

.section {
  padding-top: 8rem;
}

.page-section{
  margin: 7.5rem 0 10rem 0;
}

.mobile-view .page-section{
  margin: 3rem 0 6rem 0;
}

.font-primary {
  font-family: "Playfair Display", serif;
  font-size: 2.375rem;
}

.font-secondary {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.063rem;
}

.main-container {
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.page-transition {
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
}
.outer-div{
  padding: 20px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none; 
  filter: grayscale(100%);
}
.newsletter-modal-txt{
  color: black;
}

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  .font-primary {
    font-size: 2rem;
  }
  .font-secondary {
    font-size: 0.8rem;
  }
  .section{
    padding-top: 6rem;
  }
  .page-section{
    margin: 6rem 0 6rem 0;
  }
  .newsletter-modal-txt{
    scale: 0.8;
  }
}
