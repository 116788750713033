.navbar-container{
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: center !important;
    background-color: transparent !important;
    transition: background-color 0.2s ease-in-out;
}
/* .navbar-container:hover{
    background: linear-gradient(to bottom, #000000, transparent);
} */

.navbar-scrolled {
    background-color: white !important;
    color: rgb(0, 0, 0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    transition: background-color 0.2s ease-in-out;
}
.navbar-scrolled .nav-item{
    font-weight: 250;
}
.logo {
    height: 5rem;
}
.mobile-view .logo {
    height: 4rem;
}
.nav-container{
    width: 80vw;
}
.nav-item-container{
    margin-top: 5px;
    justify-content: space-between;
}
.nav-item {
    font-size: 0.875rem;
    text-align: center;
    font-weight: 250;
    letter-spacing: 1px;
    
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    transition: color 0.3s ease;
}

.mobile-view .nav-container{
    width: 100vw;
}
.mobile-view .nav-item{
    color: black !important;
    text-align: left;
    padding: 10px;
    font-weight: 400;
    transition: 0.3s ease-in-out;
}
.mobile-view .nav-item.active{
    color: black !important;
    font-weight: bold;
    background-color: rgb(206, 205, 205);
    border-radius: 8px;
    transition: 0.3s ease-in-out;
}
.nav-item.active {
    color: white !important;
    font-weight: bold;
}
.navbar-scrolled .nav-item.active {
    color: black !important;
    font-weight: bold;
    animation: fadeIn 0.3s;
}
.nav-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    left: 50%;
    background: #ffffff; 
    transition: width 0.3s ease, left 0.3s ease;
    -webkit-transition: width 0.3s ease, left 0.3s ease;
}
.nav-item:hover::after {
    width: 70%; 
    left: 15%;  
}
.mobile-view .nav-item:hover::after {
    width: 0%; 
}
.nav-item.active {
    font-weight: bold;
}
.nav-item.active::after {
    width: 70%; 
    left: 15%; 
}
.mobile-view .nav-item.active::after {
    width: 0%; 
}
.navbar-scrolled .nav-item::after{
    background: black; 
}
.navbar-scrolled .nav-item:hover {
    color: black;
    transition: 0.3s ease-in-out;
}
.nav-item:hover {
    color: white;
    transition: 0.3s ease-in-out;
}
.navbar-offcanvas{
    max-width: 60%;
    background-color: rgb(255, 255, 255);
}
.btn-book{
    width: 9.375rem;
    margin-left: 3.125rem;
}
.mobile-view .btn-book{
    display: none;
}
.custom-toggler {
    border-color: transparent;
}  
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
} 
.navbar-scrolled .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.scrolled {
    color: black !important;
}
.nt-scrolled {
    color: white !important;
}




/* Hide the default dropdown arrow */
.nav-item-title .dropdown-toggle::after {
    display: none !important;
  }
  
  /* Hide the default dropdown arrow in offcanvas mode */
  .navbar-offcanvas .dropdown-toggle::after {
    display: none !important;
  }
  
  /* Custom arrow styling */
  .nav-item-title {
    color: #FFFFFF; /* Default text color */
    position: relative;
    padding-right: 1.5em; /* Ensure space for the custom arrow */
  }
  
  .navbar-scrolled .nav-item-title {
    color: #000000; /* Color when scrolled */
  }
  
  /* Add a custom arrow using the ::after pseudo-element */
  .nav-item-title::after {
    content: "";
    position: absolute;
    right: 0; /* Position it at the right end of the title */
    top: 50%;
    transform: translateY(-50%);
    border: 0.4em solid transparent;
    border-top-color: inherit; /* Arrow color matches text */
    display: inline-block;
    vertical-align: middle;
  }
  
  @media (max-width: 1200px) {
    .navbar-container{
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) and (orientation: portrait), 
only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .navbar-offcanvas{
        max-width: 35%;
        background-color: rgb(255, 255, 255);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
        .nav-item{
            font-size: 0.75rem;
        }
        .btn-book{
            scale: 0.85;
        }
        .logo {
            height: 4.2rem;
        }
}