.hero-section {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow-y: hidden;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlayer {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s ease-in-out;
}
.hero-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.mobile-view .hero-content {
  width: 100vw;
  height: 100vh;
  padding: 0 2rem 0 2rem;
}
.hero-heading-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  transform: translateY(30px);
  transition: opacity 1s ease, transform 1s ease;
}
.hero-heading {
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
}
.arrow-icon {
  height: 4.375rem;
}
.bottom-viewbar-content {
  flex-wrap: wrap;
}
.bottom-viewbar {
  flex-wrap: wrap;
  position: relative;
  background-color: white;
  color: rgba(0, 0, 0, 0.573);
  height: 3.6rem;
  border-radius: 10px 10px 0 0;
  width: 80%;
  opacity: 1;
  justify-content: center;
  transition: transform 1s ease;
}
.bottom-viewbar-text {
  font-size: 1rem;
  padding-top: 1rem;
}
.bottom-viewbar-img {
  height: 1.875rem;
  margin-top: 0.7rem;
  margin-left: 1.875rem;
  margin-right: 1.25rem;
}
.social-icon-section {
  margin-left: 1.875rem;
}
.social-icon {
  margin-top: 1.1rem;
  padding-right: 1.875rem;
}
.social-icon:hover {
  color: black;
  transition: 0.1s ease-in-out;
}
/* .mobile-view .hero-heading-section {
  margin-top: 0%;
} */
.mobile-view .hero-heading-section p{
  font-size: small;
  font-weight: 200;
}
.mobile-view .bottom-viewbar {
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
}
.service-hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mobile-view .img2 {
  margin-left: 0px;
}
.mobile-view .social-icon-section {
  margin-left: 0px;
}
.overlayer.hidden {
  /* display: none; */
  background-color: rgba(0, 0, 0, 0.24);
  transition: all 1s ease-in-out;
}
.hero-heading-section.hidden {
  transform: translateY(10%);
  opacity: 0;
}
.bottom-viewbar.slide-down {
  transform: translateY(100%);
  transition: all 1s ease !important;
}

.bottom-viewbar.slide-up {
  transform: translateY(0%);
  transition: transform 1s ease !important;
}

.mobile-view .hero-heading {
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.15rem;
}

.line-1 {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
  animation-delay: 10s;
  font-size: 0.8rem;
}
@keyframes typewriter {
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1919px)  {
      .bottom-viewbar-text{
        font-size: 0.8rem;
        margin-top: -0.15rem;
      }
      .bottom-viewbar{
        height: 2.8rem;
      }
      .bottom-viewbar-img{
        height: 1.5rem;
        margin-top: 0.6rem;
        margin-left: 1.875rem;
        margin-right: 1.25rem;
      }
      .anim-typewriter{
        font-size: 0.7rem;
      }
      .hero-heading {
        font-size: 2.5rem !important;
      }
}